import React from "react"
import styled from "styled-components"

import { Color, Platform } from "../../types/contentfulTypes"

interface Props {
  color: Color
  platform: Platform
  onClick?: () => any
  isSelected?: boolean
}

interface StyledProps {
  background: string
  onClick?: () => any
  isSelected?: boolean
}

const ColorChip = ({ color, platform, onClick, isSelected }: Props) => {
  let background = ""

  switch (color?.hexCodes?.length) {
    case 1:
      background = color?.hexCodes[0]
      break

    case 2:
      // Take platform into consideration for Huawei Android
      if (platform === "Apple") {
        background = `linear-gradient(to right, ${color?.hexCodes[0]} 0%, ${color?.hexCodes[0]} 50%, ${color?.hexCodes[1]} 50%, ${color?.hexCodes[1]} 100%)`
        break
      } else {
        background = `linear-gradient(180deg, ${color?.hexCodes[0]} 0%, ${color?.hexCodes[1]} 100%)`
        break
      }

    case 3:
      background = `linear-gradient(180deg, ${color?.hexCodes[0]} 0%, ${color?.hexCodes[1]} 54.17%, ${color?.hexCodes[2]} 100%)`
      break

    case 4:
      // This case doesn't exist yet, once exists, change %
      background = `linear-gradient(180deg, ${color?.hexCodes[0]} 0%, ${color?.hexCodes[1]} 33%, ${color?.hexCodes[2]} 66%, ${color?.hexCodes[3]} 100%)`
      break

    case 5:
      background = `linear-gradient(180deg, ${color?.hexCodes[0]} 0%, ${color?.hexCodes[1]} 21.46%, ${color?.hexCodes[2]} 50.46%, ${color?.hexCodes[3]} 87%, ${color?.hexCodes[4]} 111.36%)`
      break

    default:
      break
  }

  return background ? (
    <Circle title={color?.name} background={background} onClick={onClick} isSelected={isSelected} />
  ) : null
}

export default ColorChip

const Circle = styled.div<StyledProps>`
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  background: ${props => props.background && props.background};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.12);
  border: ${props => (props.isSelected ? "1px solid rgba(255, 255, 255, 0.3)" : "none")};
  transform: ${props => (props.isSelected ? "scale(1.2)" : "scale(1)")};
  transition: 1s cubic-bezier(0.2, 0.2, 0.2, 1);

  :hover {
    transform: ${props => (props.onClick ? "translateY(-2px) scale(1.2)" : "none")};
  }
`
