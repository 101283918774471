import React from "react"
import styled from "styled-components"

import { AssetFile, Material } from "../../types/contentfulTypes"
import Helpers from "../../utils/Helpers"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  images: AssetFile[]
  material?: Material
}

interface StyledProps {
  color?: string
  length?: number
}

const MockupsCarousel = (props: Props) => {
  const { images, material } = props

  const sortedImages: AssetFile[] = Helpers.sortArrayByProperty(images, "description")

  return (
    <Wrapper>
      {sortedImages.map((image, index) => (
        <Card
          key={index}
          color={
            material === "Glass"
              ? "linear-gradient(241.11deg, #ddbbed -1.48%, #5a67e2 103.38%)"
              : "#ffffff"
          }
        >
          <Title color={material === "Glass" ? "#ffffff" : "#000000"}>{image.description}</Title>
          <Image src={image.file?.url} />
        </Card>
      ))}
    </Wrapper>
  )
}

export default MockupsCarousel

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  gap: 20px;
  justify-content: stretch;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`

const Title = styled(TextStyles.H4)<StyledProps>`
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: ${props => props.color || "#000000"};
`

const Card = styled.div<StyledProps>`
  width: 100%;
  min-width: 280px;
  height: 400px;
  background: ${props => props.color || "#ffffff"};
  border-radius: 20px;
  position: relative;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 20px;
  overflow: hidden;

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.05) translateY(-4px);

    img {
      transform: scale(1.05) translateY(25px);
    }
  }
`

const Image = styled.img`
  max-width: 300px;
  max-height: 300px;
  margin: auto;
`
