import React from "react"
import styled from "styled-components"

import { Mockup } from "../../types/contentfulTypes"

import MockupsCarousel from "./MockupsCarousel"

interface Props {
  mockup: Mockup
}

const MockupList = (props: Props) => {
  const { mockup } = props

  return (
    <Wrapper>
      {mockup.images.length > 0 && (
        <MockupsCarousel images={mockup.images} material={mockup.material} />
      )}
    </Wrapper>
  )
}

export default MockupList

const Wrapper = styled.div`
  #device-name {
    cursor: pointer;
  }
`
