import React, { useState } from "react"
import styled from "styled-components"

import { Color, Mockup } from "../../types/contentfulTypes"
import ColorChip from "../buttons/ColorChip"

import MockupList from "../lists/MockupList"
import * as TextStyles from "../styles/TextStyles"

interface Props {
  mockups: Mockup[]
  colors: Color[]
}

interface StyledProps {
  length?: number
}

const MockupsSection = (props: Props) => {
  const { mockups, colors } = props
  const [colorIndex, setColorIndex] = useState(0)

  return (
    <Wrapper>
      <TextContainer>
        <Title>{mockups[colorIndex].name}</Title>
        <ColorChipsWrapper length={colors?.length}>
          {colors?.map((color, index) => (
            <ColorChip
              key={index}
              color={color}
              platform={mockups[0].platform}
              onClick={() => setColorIndex(index)}
              isSelected={colorIndex === index}
            />
          ))}
        </ColorChipsWrapper>
      </TextContainer>
      {[mockups[colorIndex]].map((mockup, index) => (
        <MockupList mockup={mockup} key={index} />
      ))}
    </Wrapper>
  )
}

export default MockupsSection

const Wrapper = styled.div`
  display: grid;
  gap: 40px;
  max-width: 1234px;
  margin: 0 auto;
  padding: 100px 20px 0;
`

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 730px) {
    grid-template-columns: auto;
    justify-content: start;
  }
`

const ColorChipsWrapper = styled.div<StyledProps>`
  display: grid;
  max-width: 700px;
  width: fit-content;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(30px, auto));

  @media (max-width: 1100px) {
    max-width: 350px;
  }

  @media (max-width: 520px) {
    max-width: 280px;
  }
`

const Title = styled(TextStyles.H4)`
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
`
