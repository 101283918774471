import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import { graphql } from "gatsby"

import useContentful from "../../utils/hooks/useContentful"
import { MetadataReadonly, Color, Device } from "../../types/contentfulTypes"

import Layout from "../../components/layout/Layout"
import * as TextStyles from "../../components/styles/TextStyles"
import TextSection from "../../components/sections/TextSection"
import DevicesSection from "../../components/sections/MockupsSection"
import LoaderAnimation from "../../components/animations/LoaderAnimation"
import { useMouseCenterPosition } from "../../utils/hooks/useMouseCenterPosition"

interface Props {
  params: {
    "*": string
  }
  node: any
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

interface DeviceData {
  device: Device
  mockupsCount: number
  anglesCount: number
  colors: Color[]
}

const MockupPage = (props: Props) => {
  const { params, data } = props
  const slug = params["*"]
  const { status, getMockups } = useContentful()
  const [deviceData, setDeviceData] = useState<DeviceData | undefined>(undefined)

  const ref = useRef(null)
  const [centerX, centerY, bind] = useMouseCenterPosition(ref)

  useEffect(() => {
    getMockups(slug).then(response => setDeviceData(response))

    return () => setDeviceData(undefined)
  }, [])

  const frontMockup =
    deviceData &&
    (deviceData.device.mockups?.[0]?.images.find(image => image.description.includes("01")) ??
      deviceData.device.mockups?.[1]?.images[0])

  const { contentfulMetadata } = data

  const metadata = {
    title: deviceData?.device?.name
      ? `Angle 4 - ${deviceData?.device.name} Mockups`
      : "Angle 4 - Device Mockups",
    slug,
    description: `${deviceData?.device?.mockups?.[0].model} mockups. Offered in ${deviceData?.colors.length} colors and ${deviceData?.anglesCount} angles. ${contentfulMetadata?.description}`,
    keywords: deviceData?.device.tags?.join(", ") + contentfulMetadata?.keywords ?? "",
    image: contentfulMetadata?.image,
  }

  // Need to change the image

  return (
    <Layout contentfulMetadata={metadata}>
      <Wrapper>
        <Container>
          {status.state === "pending" ? (
            <LoaderAnimation />
          ) : deviceData && deviceData.device?.mockups ? (
            <>
              <HeaderContainer>
                <Illustration
                  {...bind}
                  ref={ref}
                  src={deviceData?.device?.image?.file?.url ?? frontMockup}
                  alt="illustration"
                  style={{
                    transform: `
                    rotateX(${centerY / 20}deg) 
                    rotateY(${centerX / -20}deg)
                    `,
                  }}
                />
                <TextContainer>
                  <Title>{deviceData.mockupsCount}</Title>
                  <TextSection
                    title={`${deviceData.device.mockups[0].model} Mockups`}
                    description={`Present your designs on ${deviceData.device.mockups[0].model} mockups. Offered in ${deviceData.colors.length} colors and ${deviceData.anglesCount} angles.`}
                  />
                </TextContainer>
              </HeaderContainer>
              <LazyLoad>
                <DevicesSection mockups={deviceData.device.mockups} colors={deviceData.colors} />
              </LazyLoad>
            </>
          ) : (
            <ErrorMessageText>
              Sorry, no mockups found for{" "}
              {(deviceData && deviceData.device?.name) ?? slug?.replace(/-/g, " ")}
            </ErrorMessageText>
          )}
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default MockupPage

export const query = graphql`
  query DevicePageQuery {
    contentfulMetadata(slug: { eq: "device" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  padding: 200px 0px;
`

const Container = styled.div`
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  display: grid;
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 100px;
  }
`

const Illustration = styled.img`
  display: block;
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
  padding: 0 20px;
`

const TextContainer = styled.div`
  display: grid;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .purchase {
    display: none;
  }
`

const Title = styled(TextStyles.H1)`
  font-style: normal;
  font-weight: 300;
  font-size: 140px;
  line-height: 167px;
  padding: 0 20px;

  @media (max-width: 414px) {
    font-size: 100px;
  }
`

const ErrorMessageText = styled(TextStyles.H4)`
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
`
